import React from "react";
import Particle from "./Particle";
import "./Landing.css";

function Landing() {
	Particle();
	setTimeout(() => {
		let x = document.getElementById("loading");
		x.style.width = "60%";
		document.getElementById("wedoitfaster").style.opacity = "100%";
		document.getElementById("wip").style.opacity = "100%";
		document.getElementById("perc").style.opacity = "100%";
		document.getElementById("perc").style.left = "8%";
		
	}, 1800);

	return (
		<div className="">
			<canvas id="scene"></canvas>
			<input id="copy" type="text" value="Jeck Scardy" className="hidden" />
			<p id="wip" className="text-white z-10 bottom-16 opacity-0 transition-all duration-700">Work in Progress</p>
			<p
				id="wedoitfaster"
				className="text-white uppercase z-10 bottom-64 font-semibold text-2xl opacity-0 transition-all duration-[3000ms]"
			>
				We do it faster
			</p>
			<div className="w-[80%] h-2 bg-[rgb(63,63,63)] fixed bottom-8 ml-[10%] rounded-md">
			<p id="perc" className="bottom-12 left-[-40%] opacity-0 transition-all duration-[2000ms]">60%</p>
				<div
					id="loading"
					className="w-0 transition-all duration-[2000ms] ease-in-out h-2 bg-white rounded-md"
				></div>
			</div>
		</div>
	);
}

export default Landing;
